<template>
    <div class="main">
        Made with ❤ and Vue
    </div>
</template>

<script>
export default {
    name: "FooterMenu"
}
</script>

<style scoped>
.main {
    margin-top: 5rem;
    background: var(--darkBlue);
    color: #FFF;
    padding: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
}
</style>